<template>
  <iframe
    v-if="meetingId != '' && canJoin"
    :src="FrameUrl"
    width="100%"
    height="600px"
    frameborder="0"
  ></iframe>
</template>

<script>
import axios from "axios";

export default {
  props: ["meetingId"],
  name: "zoomIframe",
  data() {
    return {
      FrameUrl: window.location.origin + "/meeting/" + this.meetingId,
      canJoin: true,
    };
  },
  methods: {
    canJoinFun() {
      axios
        .get(
          this.getApiUrl + "/online_meeting/CheckUserMeeting/" + this.meetingId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log(response.data.data.canJoin);
          if (!response.data.data.canJoin) {
            // console.log("Fafsasfalj");
            // window.location.href = "http://www.w3schools.com";
            this.canJoin = false;
            this.$router.push(
              {
                path: "/403",
              },
              () => {}
            );
          } else {
            this.canJoin = true;
          }
        });
    },
    sentMeatingId() {
      axios
        .post(
          this.getApiUrl + "/attendance/store",
          { meeting_id: this.$route.params.meetingId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        });
    },
  },
  mounted() {
    this.canJoinFun();
    this.sentMeatingId();
  },
};
</script>
